<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_scrapManage_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" style="margin-right: 16px;" v-permission="['pc_assets_scrapManage_export']" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<!-- <a-button type="primary" @click="onDiscard">申请报废</a-button> -->
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="资产编号" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input v-model:value="formModal.startCode"></a-input>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input v-model:value="formModal.endCode"></a-input>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="资产名称" class="ui-form__item">
						<a-input v-model:value="formModal.assetsName"></a-input>
					</a-form-item>
					<a-form-item label="资产分类" class="ui-form__item">
						<a-cascader v-model:value="formModal.assetsType" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
						<!-- <a-select  allow-clear v-model:value="formModal.assetsType" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select> -->
					</a-form-item>
					<a-form-item label="报废原因" class="ui-form__item">
						<!-- <a-input v-model:value="formModal.keepAccountsType"></a-input> -->
						<a-select v-model:value="formModal.keepAccountsType"  allow-clear style="width: 190px;">
							<a-select-option :value="1">盘亏</a-select-option>
							<a-select-option :value="2">损坏</a-select-option>
							<a-select-option :value="3">超过使用年限</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="会计凭证号" class="ui-form__item">
						<a-input v-model:value="formModal.accountantNum"></a-input>
					</a-form-item>
					<a-form-item label="使用单位" class="ui-form__item">
						<a-select showSearch optionFilterProp="label" v-model:value="formModal.unitId"  allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id" :label="item.name">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="使用部门" class="ui-form__item">
						<a-select allow-clear v-model:value="formModal.departmentId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="使用人" class="ui-form__item">
						<!-- <a-input v-model:value="formModal.checker"></a-input> -->
						<a-select v-model:value="formModal.userId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.username }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="状态" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.status" style="width: 190px;">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="1">已确认</a-select-option>
							<a-select-option :value="0">未确认</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="申请时间段" class="ui-form__item">
						<a-range-picker @change="onChangeFun" :value="clearTime" show-time />
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'assetsName'">资产名称</a-checkbox>
							<a-checkbox :value="'assetsNum'">资产编号</a-checkbox>
							<a-checkbox :value="'accountantNum'">会计凭证号</a-checkbox>
							<a-checkbox :value="'applyTime'">申请时间</a-checkbox>
							<a-checkbox :value="'assetsType'">资产分类</a-checkbox>
							<a-checkbox :value="'state'">状态</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id"  @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.applyTime) }}
						</template>
						<template v-if="column.key === 'status'">
							{{ record.status == 0 ? '未确认' : '已确认' }}
						</template>
						<template v-if="column.key === 'applyInfo'">
							<a v-if="record.applyInfo.length !== 0" :href="record.applyInfo">查看资料</a>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'keepAccountsType'">
							<div v-if="record.keepAccountsType === '1'">盘亏</div>
							<div v-if="record.keepAccountsType === '2'">损坏</div>
							<div v-if="record.keepAccountsType === '3'">超过使用年限</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status == 0" type="link" size="small" @click="onDiscard(record)">查看报废申请</a-button>
							<div v-else>-</div>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.applyTime) }}
						</template>
						<template v-if="column.key === 'status'">
							{{ record.status == 0 ? '否' : '是' }}
						</template>
						<template v-if="column.key === 'applyInfo'">
							<a v-if="record.applyInfo.length !== 0" :href="record.applyInfo">查看资料</a>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'keepAccountsType'">
							<div v-if="record.keepAccountsType === '1'">盘亏</div>
							<div v-if="record.keepAccountsType === '2'">损坏</div>
							<div v-if="record.keepAccountsType === '3'">超过使用年限</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status == 0" type="link" size="small" @click="onDiscard(record)">查看报废申请</a-button>
							<div v-else>-</div>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<discardModal ref="discardModalRef" @refresh="onReset"></discardModal>
	</div>
</template>

<script>
	import { getScrapListByUnit} from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import discardModal from './components/discardModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, discardModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				searchData: {},
				formModal: {},
				clearTime: [], // 存储时间 用来清空重置
				getDataFun: getScrapListByUnit,
				list: [],
				type: 'assetsScrapStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '资产分类',
					align: 'center',
					dataIndex: "assetsType"
				}, {
					title: '资产编号',
					align: 'center',
					dataIndex: "assetsNum"
				}, {
					title: '资产名称',
					align: 'center',
					dataIndex: "assetsName"
				}, {
					title: "使用单位",
					align: "center",
					dataIndex: "useUnit"
				}, {
					title: '报废原因',
					align: 'center',
					key: 'keepAccountsType',
					dataIndex: "keepAccountsType"
				}, {
					title: '补充说明',
					align: 'center',
					dataIndex: "description"
				}, {
					title: '会计凭证号',
					align: 'center',
					dataIndex: "accountantNum"
				}, {
					title: '使用部门',
					align: 'center',
					dataIndex: "departmentName"
				}, {
					title: '使用人',
					align: 'center',
					dataIndex: "checker"
				}, {
					title: '申请资料',
					align: 'center',
					key: 'applyInfo',
					dataIndex: "applyInfo"
				}, {
					title: '状态',
					align: "center",
					key: 'status',
					dataIndex: "status"
				}, {
					title: '申请时间',
					align: 'center',
					key: 'time'
					// dataIndex: "applyTime"
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		created() {
			this.onSearch()
		},	
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getScrapListByUnit({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("scrList",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				this.searchData.assetsType = this.searchData.assetsType ? this.searchData.assetsType[this.searchData.assetsType.length - 1] + '' : undefined;
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onDiscard(item) {
				this.$refs.discardModalRef.open(item);
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>